<template>
	<div class="usuario">
		<Nav />
		<Icon />
		<UserInvite ref="userInviteComponent" :entityId="entityId" @refresh="refreshList" />
		<div class="grid-box">
			<div class="main-block" :class="pageOptions.FooterClass">
				<div :page-options="pageOptions" @changeLevels="changeLevels">
					<v-layout pt-3>
						<div class="boxapp">
							<!-- boxapp head -->
							<div class="boxapp-head">
								<a href="#" @click="$router.go(-1)">
									<faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
								</a>
								<h1>usuários</h1>
							</div>
							<!-- boxapp body -->
							<div class="box-main">
								<div class="user-toolbar text-xs-right" v-if="hasPermissionToEdit">
									<button class="btn-line" v-if="false">
										<faIcon class="mr-2 fas fa-th-list" size="16px" />Upload da lista completa
									</button>

									<v-btn
										flat
										round
										color="white"
										class="btn-primary px-4 mt-2"
										large
										@click="openUserInvite"
									>
										<faIcon class="mr-2 fas fa-plus" size="16px" />Convidar usuário
									</v-btn>
									<!-- <button class="btn-line full-blue">
                      <faIcon class="mr-2 fas fa-users" size="16px" />Nova equipe
									</button>-->
								</div>
								<BorderSeparation v-if="hasPermissionToEdit" class="no-margin" />
								<v-layout row wrap w-100>
									<v-flex xs12 md9 pr-3>
										<InputText
											type="text"
											place-holder="Buscar por nome e/ou sobrenome"
											textLabel="Busca"
											v-model="search"
										/>
									</v-flex>
									<v-flex xs12 md3>
										<InputSelect
											:items="roles"
											v-model="searchRole"
											valueAttribute="id"
											textAttribute="description"
											textLabel="Filtro por perfil"
										></InputSelect>
									</v-flex>
								</v-layout>
								<BorderSeparation class="no-margin" v-if="loggedUser.personId != 0" />
								<v-layout w-100 v-if="loggedUser.personId != 0">
									<v-avatar :size="100" color="grey lighten-4 mr-4">
										<img :src="getProfileImage(loggedUser.name)" />
									</v-avatar>
									<div class="name-profile mt-2">
										<h2 class="text-red">{{getRoleDescription(loggedUser)}}</h2>
										<h3>{{loggedUser.name}}</h3>
										<p class="font-italic text-gray-dark">{{getOccupation(loggedUser)}}</p>
										<small
											class="text-gray-dark"
										>Último login: {{loggedUser.lastLogin ? formatterHelper.formatDateAndHour(loggedUser.lastLogin) : " - " }}</small>
										<small
											class="text-gray-dark"
											v-if="loggedUser.currentUserRole && loggedUser.currentUserRole.expirationDate ? true : false"
										>Data vigência: {{ getExpirationDate(loggedUser) }}</small>
									</div>
								</v-layout>
								<BorderSeparation v-if="filteredUsers.length > 0 " />
								<div class="grid-user mt-3" v-if="filteredUsers.length > 0 ">
									<div class="text-md-center mb-5" v-for="user in filteredUsers" :key="user.id">
										<div class="text-xs-right mb-2 p-relative">
											<v-avatar :size="100" color="grey lighten-4 user-photo">
												<img class="b-s-8 text-white" :src="getProfileImage(user.name)" />
											</v-avatar>
											<button v-if="hasPermissionToEdit" @click="openEdit(user)">
												<faIcon class="mr-3" type="fal" name="pencil" size="16px" colorFont="#c3c6d5" />
											</button>
											<!-- <button>
												<faIcon class="mr-1" type="fal" name="sign-out-alt" size="16px" colorFont="#c3c6d5"/>
											</button>-->
										</div>
										<div class="bg-gray-light br-8 p-relative o-hidden">
											<div class="pa-4 pt-5">
												<!-- <div class="bg-violet f-size-12 user-marker">RH</div> -->
												<h2 class="mt-2">{{user.name}}</h2>
												<p class="font-italic text-gray-dark ma-0">{{getOccupation(user)}}</p>
											</div>
											<hr class="md white ma-0" />
											<div class="pa-4">
												<h3 class="text-primary">{{getRoleDescription(user)}}</h3>
												<small
													class="text-gray-dark"
												>Último login: {{user.lastLogin ? formatterHelper.formatDateAndHour(user.lastLogin) : " - " }}</small>
												<br />
												<small
													class="text-gray-dark"
													v-if="user.currentUserRole && user.currentUserRole.expirationDate ? true : false"
												>Data vigência: {{ getExpirationDate(user) }}</small>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="box-help-spacer"></div>
						<HelpBox :text="text"></HelpBox>
					</v-layout>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";
import UserInvite from "./modal/UserInvite.vue";
import PersonService from "@/scripts/services/person.service";
import UserService from "@/scripts/services/user.service";
import Nav from "@/components/Nav.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import PageOptions from "@/scripts/models/pageOptions.model";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import HelpBox from "@/components/HelpBox.vue";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import { mapGetters } from "vuex";
import Entity from "@/scripts/models/entity.model";
import UserPerson from "@/scripts/models/userPerson.model";
import Permissions from "@/scripts/models/enums/permissions.enum";
import { UserOccupation } from "@/scripts/models/enums/userOccupation.enum.js";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
export default {
	components: {
		HelpBox,
		Nav,
		Breadcrumb,
		Modal,
		UserInvite
	},
	props: [""],
	data() {
		return {
			text: HelpBoxText.Users,
			formatterHelper: new FormatterHelper(),
			pageOptions: new PageOptions(),
			personService: new PersonService(),
			userService: new UserService(),
			// currentEntity: new Entity(),
			loggedUser: new UserPerson(),
			search: "",
			searchRole: "0",
			hasPermissionToEdit: false,
			roles: [],
			userOccupation: UserOccupation,
			entityId: 0,
			userList: []
		};
	},
	computed: {
		...mapGetters(["hasPermission"]),
		filteredUsers: function() {
			if (this.searchRole !== "0") {
				return this.userList.filter(user => {
					return (
						user.name
							.toLowerCase()
							.match(this.search.toLowerCase()) &&
						user.currentUserRole.roleId ===
							parseInt(this.searchRole)
					);
				});
			} else {
				return this.userList.filter(user => {
					return user.name
						.toLowerCase()
						.match(this.search.toLowerCase());
				});
			}
		}
	},
	created() {
		this.entityId = this.$route.query.entityId;
		if (this.entityId) {
			this.personService.getRolesByEntity(this.entityId).then(
				function(data) {
					this.roles = data;
				}.bind(this)
			);
		}

		this.hasPermissionToEdit =
			this.hasPermission(
				[Permissions.CRUDUserCompany, Permissions.CRUDUserInstitution],
				this.entityId,
				false
			) || this.hasPermission([Permissions.CRUDUserAll]);

		this.pageOptions.changeClass(null);
		// this.pageOptions.currentPage = 0;
		// this.$emit("changeLevels", this.breadcrumbLevels);
		this.personService.getLoggedPersonUser(this.entityId).then(
			function(data) {
				if (data.currentUserRole) {
					this.loggedUser = data;
				}
			}.bind(this)
		);
		this.refreshList();
	},
	methods: {
		refreshList() {
			this.personService.listPersonByEntity(this.entityId).then(
				function(data) {
					this.userList = data;
				}.bind(this)
			);
		},
		getExpirationDate(loggedUser) {
			if (
				loggedUser.currentUserRole &&
				loggedUser.currentUserRole.expirationDate
			) {
				return this.formatterHelper.formatDate(
					loggedUser.currentUserRole.expirationDate
				);
			}
			return "";
		},
		getOccupation(obj) {
			if (obj && obj.currentUserRole && obj.currentUserRole.occupation) {
				if (
					obj.currentUserRole.occupation ==
					this.userOccupation.Representative
				) {
					return obj.currentUserRole.occupationDesc;
				} else {
					return this.userOccupation.properties[
						obj.currentUserRole.occupation
					].name;
				}
			} else {
				return "";
			}
		},
		getRoleDescription(obj) {
			if (
				obj.currentUserRole &&
				obj.currentUserRole.roleId &&
				this.roles.length > 0
			) {
				return this.roles.find(
					item => item.id == obj.currentUserRole.roleId
				).description;
			} else {
				return "";
			}
		},
		getProfileImage(personName) {
			let url =
				"https://ui-avatars.com/api/?background=F1F2F5&color=80849A&size=150&font-size=0.3&name=";
			if (personName != null) url += personName.split().join("+");
			return url;
		},
		openEdit(user) {
			this.$refs.userInviteComponent.open(user);
		},
		openUserInvite() {
			this.$refs.userInviteComponent.open(new UserPerson());
		},
		changeLevels(levels) {
			this.breadcrumbLevels = levels;
		}
	}
};
</script>